import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { StorageModule } from '@ngx-pwa/local-storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, of } from 'rxjs';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { GlobalErrorHandler } from './interceptors/global-error-handler.interceptor';
import { WidgetComponent } from './widget/widget.component';

import { de_CH } from '../assets/i18n/de_CH';
import { en_US } from '../assets/i18n/en_US';
import { es_ES } from '../assets/i18n/es_ES';
import { fi_FI } from '../assets/i18n/fi_FI';
import { fr_CH } from '../assets/i18n/fr_CH';
import { it_CH } from '../assets/i18n/it_CH';
import { nl_NL } from '../assets/i18n/nl_NL';
import { pl_PL } from '../assets/i18n/pl_PL';
import { sv_SE } from '../assets/i18n/sv_SE';

// locals import required for currency pipe and date pipe
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faArrowLeftLong,
  faCalendar,
  faCalendarCheck,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faDownload,
  faEllipsisH,
  faHandshake,
  faHospitalUser,
  faList,
  faMinus,
  faPlus,
  faQuestionCircle,
  faSquare,
  faTh,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUser,
  faUserTie,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppComponent } from './app.component';

// required for currency and date pipe
registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'ch');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFrCH, 'fr_CH');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeItCh, 'it_CH');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeSv, 'sv');

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'de_CH': return of(de_CH);
      case 'en_US': return of(en_US);
      case 'fi_FI': return of(fi_FI);
      case 'fr_CH': return of(fr_CH);
      case 'it_CH': return of(it_CH);
      case 'nl_NL': return of(nl_NL);
      case 'pl_PL': return of(pl_PL);
      case 'es_ES': return of(es_ES);
      case 'sv_SE': return of(sv_SE);
      default: return of(de_CH);
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BrowserModule,
    StorageModule.forRoot({ IDBNoWrap: false }),
  ],
  providers: [
    provideAnimations(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: false }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private injector: Injector, library: FaIconLibrary) {
    library.addIcons(faCheckCircle);
    library.addIcons(faChevronUp);
    library.addIcons(faChevronDown);
    library.addIcons(faSquare);
    library.addIcons(faQuestionCircle);
    library.addIcons(faTimesCircle);
    library.addIcons(faChevronLeft);
    library.addIcons(faChevronRight);
    library.addIcons(faChevronCircleRight);
    library.addIcons(faChevronCircleLeft);
    library.addIcons(faList);
    library.addIcons(faUser);
    library.addIcons(faCalendar);
    library.addIcons(faCheck);
    library.addIcons(faEllipsisH);
    library.addIcons(faPlus);
    library.addIcons(faTh);
    library.addIcons(faTrash);
    library.addIcons(faTrashAlt);
    library.addIcons(faTimes);
    library.addIcons(faMinus);
    library.addIcons(faDownload);
    library.addIcons(faUsers);
    library.addIcons(faUserTie);
    library.addIcons(faHospitalUser);
    library.addIcons(faCircleNotch);
    library.addIcons(faClock);
    library.addIcons(faCalendarCheck);
    library.addIcons(faArrowLeftLong);
    library.addIcons(faHandshake);
  }

  ngDoBootstrap() {
    const el = createCustomElement(WidgetComponent, {
      injector: this.injector,
    });
    customElements.define('calenso-booking', el);
  }
}
