<div class="appointment-navigation-adsense">
  @if (showAds) {
    <ng-adsense />
  }
</div>

<div class="d-flex align-items-center">
  <ul class="nav nav-pills nav-fill w-100">
    @if (partner?.is_customer_profiles_feature_enabled === 1 && partner?.customer_profiles?.length > 1) {
      <li
        class="nav-item"
        [ngClass]="{
          'active': viewMode == appointmentConstant.CUSTOMER_TYPE,
          'valid': partner?.customer_profiles?.length > 0
        }"
        [attr.aria-current]="viewMode == appointmentConstant.CUSTOMER_TYPE ? 'step' : undefined"
      >
        <button
          type="button"
          class="nav-link btn"
          #tab
          (click)="navigateToEvent.next(appointmentConstant.CUSTOMER_TYPE)"
        >
          <span class="d-none d-sm-block">
            {{ 'customer_type' | translate }}
          </span>
          <span class="d-sm-none d-flex justify-content-center">
            <fa-icon class="icon" icon="user" />
          </span>
        </button>
      </li>
    }

    @if(!isServicesTabDisabled) {
      <li
        class="nav-item"
        [ngClass]="{
          'active': viewMode == appointmentConstant.APPOINTMENT,
          'valid': appointmentState.services.length > 0
        }"
        [attr.aria-current]="viewMode == appointmentConstant.APPOINTMENT ? 'step' : undefined"
      >
        <button
          type="button"
          [disabled]="isServicesTabDisabled || firstButtonDisabled"
          class="nav-link btn"
          #tab
          (click)="navigateToEvent.next(appointmentConstant.APPOINTMENT)"
        >
          <span class="d-none d-sm-block">
            {{ widgetServiceLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          </span>
          <span class="d-sm-none d-flex justify-content-center">
            <fa-icon class="icon" icon="list" />
          </span>
        </button>
      </li>
    }

    @if (!hideResourceStep && workersAvailable?.length > 1 && appointmentState.services?.length) {
      <li
        class="nav-item"
        [ngClass]="{
          'active': viewMode == appointmentConstant.WORKER,
          'valid': appointmentState.worker != null && viewMode != appointmentConstant.APPOINTMENT
        }"
        [attr.aria-current]="viewMode == appointmentConstant.WORKER ? 'step' : undefined"
      >
        <button
          type="button"
          class="nav-link btn"
          [disabled]="secondButtonDisabled"
          #tab
          (click)="navigateToEvent.next(appointmentConstant.WORKER)"
        >
          <span class="d-none d-sm-block">
            {{ widgetResourceLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          </span>
          <span class="d-sm-none d-flex justify-content-center">
            <fa-icon class="icon" icon="user" />
          </span>
        </button>
      </li>
    }

    @if (appointmentState?.services?.length && appointmentState?.worker && appointmentState?.has_meeting_types_restrictions) {
      <li
        class="nav-item"
        [ngClass]="{ 
          'active': viewMode == appointmentConstant.MEETING_PROVIDERS, 
          'valid': appointmentState.meeting_type_id && viewMode !== appointmentConstant.MEETING_PROVIDERS 
        }"
        [attr.aria-current]="viewMode == appointmentConstant.MEETING_PROVIDERS ? 'step' : undefined"
      >
        <button
          type="button"
          class="nav-link btn"
          #tab
          [disabled]="!appointmentState.worker"
          (click)="navigateToEvent.next(appointmentConstant.MEETING_PROVIDERS)"
        >
          <span class="d-none d-sm-block">
            {{ widgetMeetingTypesLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          </span>
          <span class="d-sm-none d-flex justify-content-center">
            <fa-icon class="icon" icon="handshake" />
          </span>
        </button>
      </li>
    }

    <li
      class="nav-item"
      [ngClass]="{
        'active': viewMode == appointmentConstant.DATE,
        'valid': appointmentState.date != null && cart?.length > 0 && viewMode !== appointmentConstant.DATE
      }"
      [attr.aria-current]="viewMode == appointmentConstant.DATE ? 'step' : undefined"
    >
      <button
        type="button"
        class="nav-link btn"
        #tab
        [disabled]="thirdButtonDisabled"
        (click)="navigateToEvent.next(appointmentConstant.DATE)"
      >
        <span class="d-none d-sm-block">
          {{ widgetDateLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
        </span>
        <span class="d-sm-none d-flex justify-content-center">
          <fa-icon class="icon" icon="calendar" />
        </span>
      </button>
    </li>

    <li
      class="nav-item"
      [ngClass]="{
        'active': viewMode == appointmentConstant.PERSONAL_INFO,
        'valid': formService.personalFormValid && appointmentState.date != null && cart?.length > 0 && viewMode === appointmentConstant.SUMMARY
      }"
      [attr.aria-current]="viewMode == appointmentConstant.PERSONAL_INFO ? 'step' : undefined"
    >
      <button
        type="button"
        class="nav-link btn"
        #tab
        [disabled]="forthButtonDisabled"
        aria-label="lang-dropdown"
        (click)="cart?.length ? navigateToEvent.next(appointmentConstant.PERSONAL_INFO) : ignore()"
      >
        <span class="d-none d-sm-block">
          @if (partner?.split_personal_form_and_summary_in_widget === 1) {
            {{ widgetNavigationPersonalDetailsLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          } @else {
            {{ widgetPersonalLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          }
        </span>
        <span class="d-sm-none d-flex justify-content-center">
          <fa-icon class="icon" icon="check" />
        </span>
      </button>
    </li>

    @if (partner?.split_personal_form_and_summary_in_widget === 1) {
      <li
        class="nav-item"
        [ngClass]="{
          'active': viewMode == appointmentConstant.SUMMARY
        }"
        [attr.aria-current]="viewMode == appointmentConstant.SUMMARY ? 'step' : undefined"
      >
        <button
          type="button"
          class="nav-link btn"
          #tab
          [disabled]="fifthButtonDisabled"
          (click)="summaryTabClick()"
        >
          <span class="d-none d-sm-block">
            {{ widgetPersonalLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          </span>
          <span class="d-sm-none d-flex justify-content-center">
            <fa-icon class="icon" icon="check" />
          </span>
        </button>
      </li>
    }
  </ul>

  @if (langSwitcher && supportedWidgetLanguages?.length > 1) {
    <div>
      <div ngbDropdown class="d-inline-block">
        <button class="nav-link lang-dropdown" id="nav-lang-dropdown" ngbDropdownToggle aria-label="lang-dropdown" >
          <span>
            @for (language of widgetConstants.LANGUAGES; track language.value) {
              @if (lang === language.locale) {
                <img width="20px" height="20px" title="{{ language.text | titlecase }}" alt="{{ language.text | titlecase }}" src="{{ deployUrl + language.flagIcon }}" />
              }
            }
          </span>
        </button>

        <div class="lang-dropdown-menu" ngbDropdownMenu aria-labelledby="nav-lang-dropdown">
          @for (language of widgetConstants.LANGUAGES; track language.value) {
            @if (lang !== language.locale && supportedWidgetLanguages && supportedWidgetLanguages.indexOf(language.value) > -1) {
              <button ngbDropdownItem class="lang-dropdown-menu-item" href="javascript:void(0)" (click)="switchLanguage(language.locale)">
                <span class="d-flex justify-content-center">
                  <img width="20" height="20" alt="{{ language.text | titlecase }}" title="{{ language.text | titlecase }}" src="{{ deployUrl + language.flagIcon }}" />
                </span>
              </button>
            }
          }
        </div>
      </div>
    </div>
  }
</div>
