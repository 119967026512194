import { NgClass, TitleCasePipe } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AdsenseModule } from 'ng2-adsense';
import { environment } from '../../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { UserDao } from '../../../../db-models/user-data-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { CartItem } from '../../../../models/cart.model';
import { GlobalObjects, Partner } from '../../../../models/global';
import { AppointmentState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { AppointmentService } from '../../../../services/appointment.service';
import { FormsService } from '../../../../services/forms.service';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';

const modules = [AdsenseModule, FontAwesomeModule, TranslateModule];
const pipes = [TranslationPipe, TitleCasePipe];

@Component({
  selector: 'app-appointment-navigation-bar',
  templateUrl: './appointment-navigation-bar.component.html',
  styleUrls: ['./appointment-navigation-bar.component.scss'],
  standalone: true,
  imports: [NgClass, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, ...modules, ...pipes]
})
export class AppointmentNavigationBarComponent implements OnChanges {

  @Input() protected supportedWidgetLanguages: string[];
  @Input() protected appointmentState: AppointmentState;
  @Input() protected viewMode: string;
  @Input() protected workersAvailable: UserDao[];
  @Input() protected cart: CartItem[];
  @Input() protected showAds: boolean;
  @Input() protected isServicesTabDisabled: boolean;
  @Input() protected widgetColorConf: WidgetColorConf;
  @Input() protected hideResourceStep: boolean;
  @Input() protected partner: Partner;
  @Input() protected lang: string;
  @Input() protected globals: GlobalObjects;
  @Input() protected widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() protected langSwitcher = true;
  @Input({ transform: booleanAttribute }) protected appointmentFormStatus = false;

  @Output('navigateToEvent') navigateToEvent = new EventEmitter<any>();

  protected readonly appointmentConstant = APPOINTMENT_CONSTANT;
  protected readonly templateContent = TRANSLATION_TEMPLATES;
  protected readonly deployUrl = environment.deployUrl;
  protected readonly widgetConstants = WIDGET_CONSTANTS;

  private appointmentService = inject(AppointmentService);
  public formService = inject(FormsService);
  private translate = inject(TranslateService);

  protected firstButtonDisabled = false;
  protected secondButtonDisabled = false;
  protected thirdButtonDisabled = false;
  protected forthButtonDisabled = false;
  protected fifthButtonDisabled = false;
  protected meetingTypeButtonDisabled = false;
  protected widgetServiceLabelTemplate: CustomBookingMessageTemplate;
  protected widgetResourceLabelTemplate: CustomBookingMessageTemplate;
  protected widgetDateLabelTemplate: CustomBookingMessageTemplate;
  protected widgetPersonalLabelTemplate: CustomBookingMessageTemplate;
  protected widgetNavigationPersonalDetailsLabelTemplate: CustomBookingMessageTemplate;
  protected widgetMeetingTypesLabelTemplate: CustomBookingMessageTemplate;

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
    this.appointmentService.disableFirstTwoButtonsEvent.subscribe(() => {
      this.firstButtonDisabled = true;
      this.secondButtonDisabled = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.widgetTemplates && changes?.widgetTemplates?.currentValue) {
      this.widgetTemplates?.length && this.setupTemplates();
    }
  }

  private setupTemplates() {
    this.widgetServiceLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_SERVICE_LABEL);
    this.widgetServiceLabelTemplate && (this.widgetServiceLabelTemplate.is_multi_language = 1);

    this.widgetResourceLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_RESOURCE_LABEL);
    this.widgetResourceLabelTemplate && (this.widgetResourceLabelTemplate.is_multi_language = 1);

    this.widgetDateLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_DATE_LABEL);
    this.widgetDateLabelTemplate && (this.widgetDateLabelTemplate.is_multi_language = 1);

    this.widgetPersonalLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_SUMMARY_LABEL);
    this.widgetPersonalLabelTemplate && (this.widgetPersonalLabelTemplate.is_multi_language = 1);

    this.widgetPersonalLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_SUMMARY_LABEL);
    this.widgetPersonalLabelTemplate && (this.widgetPersonalLabelTemplate.is_multi_language = 1);

    this.widgetNavigationPersonalDetailsLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NAV_PERSONAL_DETAILS_LABEL);
    this.widgetNavigationPersonalDetailsLabelTemplate && (this.widgetNavigationPersonalDetailsLabelTemplate.is_multi_language = 1);

    this.widgetMeetingTypesLabelTemplate = this.widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_MEETING_TYPES_LABEL);
    this.widgetMeetingTypesLabelTemplate && (this.widgetMeetingTypesLabelTemplate.is_multi_language = 1);
  }

  protected ignore() {
  }

  protected switchLanguage(language: string) {
    this.lang = language;
    this.translate.use(language);
    this.globals.user_selected_language = language;
  }

  protected summaryTabClick(): void {
    if (this.cart?.length && this.appointmentState.date && this.formService.imageUploaded && this.formService.personalFormValid) {
      this.navigateToEvent.next(APPOINTMENT_CONSTANT.SUMMARY);
    }
  }
}
