export const APPOINTMENT_CONSTANT = {
  CALENDAR_LOADER: 'CALENDAR_LOADER',
  CALENDAR: 'calendar',
  WORKER_LOADER: 'WORKER_LOADER',
  CUSTOMER_TYPE: 'customer_type',
  APPOINTMENT: 'appointment',
  PERSONAL_INFO: 'personal-info',
  WORKER: 'worker',
  MEETING_PROVIDERS: 'meetingProviders',
  DATE: 'date',
  SUMMARY: 'summary',
  PAYMENT: 'payment',
  APPOINTMENT_STATE_LS: 'appointmentStateLs-',
  APPOINTMENT_GLOBAL_USER_LS: 'globalUserLs',
  APPOINTMENT_BROKER_USER_LS: 'globalBrokerUserLs',
  APPOINTMENT_CART_LS: 'appointmentCartLs-',
  APPOINTMENT_CUSTOMFIELDS_LS_NAME: 'appointmentCustomFieldsLs',
  APPOINTMENT_QUALIFICATIONQUESTION_LS_NAME: 'appointmentQualificationQuestionLs',
  APPOINTMENT_CALENDAR_SLOTS_COUNT_LS_NAME: 'appointmentCalendarSlotsCountLs-',
};
